<template>
	<div>
		<div class="preview-basic">
			<table>
				<tr>
					<td class="label">{{ $t('object2623') }}</td>
					<td>{{ preview.OName }}</td>
					<td>
						{{ preview.OEmail }}
						<div v-if="preview.OWeb" v-html="getWebAddressesHtml(preview.OWeb)"></div>
					</td>
					<td v-html="getPhonesHtml(preview.OPhones)"></td>
				</tr>
				<tr>
					<td class="label">{{ $t('kontact') }}</td>
					<td>{{ preview.PName }}</td>
					<td>
						{{ preview.PEmail }}
						<div v-if="preview.PWeb" v-html="getWebAddressesHtml(preview.PWeb)"></div>
					</td>
					<td v-html="getPhonesHtml(preview.PPhones)"></td>
				</tr>
				<tr>
					<td class="label">{{ $t('billingaddress2625') }}</td>
					<td colspan="3">{{ getInvoiceAddress() }}</td>
				</tr>
			</table>
		</div>
		<products-table :objectId="objectId" :userId="userId" :editable="false" :markCurrentPackage="true" :showTitle="false" />
	</div>
</template>

<script>
import productsTable from '../../modules/user-products/products.table';

export default {
	name: 'objectPreview',
	props: {
		preview: { type: Object },
		objectId: { type: Number },
		userId: { type: Number },
	},
	components: { productsTable },
	methods: {
		getInvoiceAddress() {
			let ico = this.preview.InvaddrIco == '' ? '' : this.$i18n.t('companyid') + ' ' + this.preview.InvaddrIco;
			let dic = this.preview.InvaddrDic == '' ? '' : this.$i18n.t('taxid') + ' ' + this.preview.InvaddrDic;
			let icdph = this.preview.InvaddrIcdph == '' ? '' : this.$i18n.t('vatid') + ' ' + this.preview.InvaddrIcdph;

			return [
				this.preview.InvaddrName,
				this.preview.InvaddrStreet,
				this.preview.InvaddrZip,
				this.preview.InvaddrCity,
				ico,
				dic,
				icdph,
			]
				.filter((x) => typeof x == 'string' && x.length > 0)
				.join(', ');
		},
		getPhonesHtml(phones) {
			var result = '';
			if (phones) {
				let list = phones.split(';');
				for (let i = 0; i < list.length; i++) {
					result += '<a href="tel:' + list[i] + '">' + list[i] + '</a><br/>';
				}
			}
			return result;
		},
		getWebAddressesHtml(webs) {
			var result = '';
			let list = webs.split(';');
			for (let i = 0; i < list.length; i++) {
				result += '<a href="' + this.getWebAddressUrl(list[i]) + '">' + this.getWebAddressText(list[i]) + '</a><br/>';
			}
			return result;
		},
		getWebAddressUrl(link) {
			if (link.isNullOrWhiteSpace()) {
				return link;
			}

			if (link.toLowerCase().startsWith('http') == false) {
				return 'http://' + link;
			}
			return link;
		},
		getWebAddressText(link) {
			if (link.isNullOrWhiteSpace()) {
				return link;
			}

			try {
				var url = new URL(link);
				return url.hostname + url.pathname;
			} catch {
				return link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.preview-basic {
	padding: 10px;

	table {
		td {
			vertical-align: top;
			padding-bottom: 15px;
		}

		td:not(.label) {
			padding-left: 50px;
		}
	}
}
</style>
