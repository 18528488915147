export const editLinksListItems = [
	{
		pageName: 'objectManagement/generalInfo/',
		id: 1,
		title: 'basicinfo',
	},
	{
		pageName: 'objectManagement/rooms/',
		id: 2,
		title: 'rooms',
		forAu: true,
	},
	{
		pageName: 'objectManagement/equipment/',
		id: 3,
		title: 'amenities',
	},
	{ pageName: 'objectManagement/contact/', id: 4, title: 'contacts2395' },
	{ pageName: 'objectManagement/location/', id: 5, title: 'location' },
	{
		pageName: 'objectManagement/description/',
		id: 6,
		title: 'descriptionandl',
	},
	{ pageName: 'objectManagement/pricelist/', id: 7, title: 'pricesanddiscou' },
	{ pageName: 'objectManagement/gallery/', id: 8, title: 'photos' },
	{
		pageName: 'objectManagement/occupancy/',
		id: 9,
		title: 'availability',
	},
	{
		pageName: 'objectManagement/connectToWeb/',
		id: 10,
		title: 'connecttoweb3149',
	},
	{ pageName: 'ubytovatel-statistiky/', id: 11, title: 'statistics' },
	{
		pageName: 'ubytovatel-prehlad-rezervacii/',
		id: 12,
		title: 'reservations',
	},
];
