<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:ref="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:customFilterDropdowns="filterDropdowns"
		:customHeadingTranslations="{ RemarkOwner: $t('colleague') }"
		headerTitle="objects2378"
		tooltipMsg="manualforobject"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'Id', ascending: 0 }"
		modelId="ObjectId"
		addNewRecordMsg
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="ObjectTable"
	>
		<template #filter__Remark>
			<div class="d-flex flex-row align-items-center">
				<b-form-select @change="handleFilter()" v-model="filterModel.Remark" :options="filterDropdowns.Remark"></b-form-select>
				<label>&nbsp;&nbsp;</label>
				<b-form-select
					@change="handleFilter()"
					v-model="filterModel.RemarkOwner"
					:options="filterDropdowns.RemarkOwner"
				></b-form-select>
			</div>
		</template>

		<template #Remark="list">
			<remark-indicate
				:entityId="list.row.Id"
				:entityTypeId="remarkEntityType"
				:refreshMethod="tableGetData"
				:defaultRemark="list.row.Remark"
				:administratorList="administratorList"
			></remark-indicate>
		</template>

		<template #UserId="list">
			<a :href="`/contact/${list.row.ContactId}`" target="_blank">P{{ list.row.UserId }}</a>
		</template>

		<template #Id="list">
			<div :id="`obj-${list.row.Id}`" class="object-id">O{{ list.row.Id }}</div>
			<b-popover
				:target="`obj-${list.row.Id}`"
				placement="auto"
				triggers="hover"
				delay="200"
				@show="itemIdToPreview = list.row.Id"
				custom-class="object-preview-popover"
			>
				<!--
						without this div it's not render if v-if used
						v-if is neccessary to avoid rendering for each row
				-->
				<div>
					<object-preview
						:preview="list.row.Preview"
						:objectId="list.row.Id"
						:userId="list.row.UserId"
						v-if="itemIdToPreview == list.row.Id"
						class="object-preview"
					/>
				</div>
			</b-popover>
		</template>

		<template #Name="list">
			<a :href="`${list.row.UrlDomain}/${list.row.UrlDetail}?tkn=${user.AutologCode}`" target="_blank">{{ list.row.Name }}</a>
		</template>

		<template #RecommendId="list">
			<recommend-cell
				v-if="list.row.IsRecommendAllowed"
				:contactId="list.row.ContactId"
				:recommendId="list.row.RecommendId"
				:recommendValue="list.row.RecommendValue"
				:recommendIdTexts="recommendIdTexts"
			></recommend-cell>
		</template>

		<template #Created="list">{{ list.row.Created | dateTime }}</template>

		<template #ChangedByOwner="list">{{ list.row.ChangedByOwner | dateTime }}</template>

		<template #ChangedByAdmin="list">{{ list.row.ChangedByAdmin | dateTime }}</template>

		<template #VerifiedDate="list">{{ list.row.VerifiedDate | dateTime }}</template>

		<template #ValidTo="list">{{ list.row.ValidTo | date }}</template>

		<template #IsVerified="list">
			<div class="d-flex flex-row">
				<boolean-display :boolValue="list.row.IsVerified" />
				<boolean-display
					:boolValue="list.row.Condition == objectCondition.verified"
					:badgeTitle="getConditionTagText(1)"
					v-if="list.row.Condition == objectCondition.verified"
					:title="conditionDict[1].Text"
					class="ml-2"
				/>
				<boolean-display
					:boolValue="list.row.Condition == objectCondition.notVerified"
					:badgeTitle="getConditionTagText(2)"
					v-if="list.row.Condition == objectCondition.notVerified"
					:title="conditionDict[2].Text"
					class="ml-2"
				/>
				<boolean-display
					:boolValue="list.row.Condition == objectCondition.objectState"
					:badgeTitle="getConditionTagText(3)"
					v-if="list.row.Condition == objectCondition.objectState"
					:title="conditionDict[3].Text"
					class="ml-2"
				/>
				<note-display :value="list.row.InternalNote" v-if="list.row.InternalNote" class="ml-2" />
			</div>
		</template>

		<template #Edit="list">
			<div class="d-flex flex-row align-items-center justify-content-end">
				<div>
					<b-dropdown id="dropdown-header" :text="$t('edit2391')" class="mr-1" variant="primary">
						<b-dropdown-item
							v-for="link in getEditLinksListItems(list.row.AccommodationUnitsAllowed)"
							:key="link.id"
							target="_blank"
							:href="`${list.row.UrlDomain}/${link.pageName}${list.row.Id}?tkn=${user.AutologCode}`"
						>
							<span>{{ $t(link.title) }}</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<email-send-dropdown
					:buttonText="$t('sendanoffer')"
					:pesLogTypeId="pesLogTypeId"
					:offersList="emailsList"
					:entityId="+list.row.Id"
					:withTable="false"
				></email-send-dropdown>
				<a
					:href="`${list.row.UrlDomain}/userManagement/myobjects/${list.row.Id}?tkn=${list.row.UserAutologCode}`"
					target="_blank"
				>
					<megau-button classprop="btn btn-warning ml-1" icon="key" :tooltip="$t('logintothelandl')"></megau-button>
				</a>
				<megau-button
					classprop="btn btn-success mr-1 ml-1"
					icon="cogs"
					:tooltip="$t('settings')"
					@handleClick="redirectToDetail(list.row.Id)"
				></megau-button>
			</div>
		</template>
	</table-page>
</template>

<script>
import { cloneDeep } from 'lodash';
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { getColumnDropdownOptions, setDropdownOptions } from '@/components/general/utils';
import RemarkIndicate from '@/components/table/remark-indicate';
import RecommendCell from '@/components/table/recommend-cell';
import NoteDisplay from '@/components/common/note-display';
import * as links from '@/router/links';
import utils from '@/services/helpers/utils';
import apiUser from '@/services/api/user.api';
import auth from '@/services/auth.service';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceEnums from '@/services/service/enums.service';
import enums from '@/services/helpers/enums';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';
import BooleanDisplay from '@/components/common/boolean-display';
import ObjectPreview from './object.preview';

import { editLinksListItems } from './object.service';
import { tableColumns } from './object.table-data';

export default {
	name: 'ObjectTable',

	components: {
		TablePage,
		BooleanDisplay,
		RemarkIndicate,
		RecommendCell,
		NoteDisplay,
		ObjectPreview,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectListItem',
			administratorList: [],
			filterDropdowns: {
				Remark: [],
				RemarkOwner: [],
			},
			remarkEntityType: enums.remarkEntityType.Object,
			itemIdToPreview: 0,
			user: auth.GetUser(),
			objectCondition: enums.objectCondition,
			conditionDict: serviceEnums.getEnumDict('objectCondition'),

			pesLogTypeId: enums.sendLogsTypeEnum.LodgingObject,
			emailsList: [],
			useQuickFilter: true,
		};
	},

	computed: {
		recommendIdTexts() {
			return getColumnDropdownOptions(this.columns, 'RecommendId');
		},
	},

	async created() {
		const [columns, emailSubjects, _] = await Promise.all([
			this.prepareColumns(tableColumns),
			this.getEmailSubjects(),
			this.getAdminAndRemarkList(),
		]);

		this.columns = columns;
		this.emailsList = emailSubjects;
	},

	methods: {
		redirectToDetail(ObjectId = 0) {
			this.$router.push({
				name: links.objectDetailName,
				params: { ObjectId },
			});
		},

		async prepareColumns(columns) {
			const [registrationRecommends] = await Promise.all([serviceDictionary.getRegistrationRecommends()]);

			let cols = prepareRecommendId(columns);
			cols = prepareIsVerified(columns);

			return cols;

			function prepareRecommendId(columns) {
				const values = registrationRecommends.registrationRecommendSelectorItems;

				return setDropdownOptions(columns, 'RecommendId', values);
			}

			function prepareIsVerified(columns) {
				const values = serviceEnums.getEnumForDropdown('objectStateFilter', true);

				return setDropdownOptions(columns, 'IsVerified', values);
			}
		},

		async getAdminAndRemarkList() {
			let admins = await apiUser.getAdministratorList();
			this.administratorList = cloneDeep(admins.data);
			this.administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
			admins.data.unshift({ id: -1, name: this.$i18n.t('unassigned') });
			const RemarkOwner = utils.convertItems(
				admins.data,
				function (item) {
					return { value: item.id, text: item.name };
				},
				{ value: null, text: '' }
			);
			const Remark = serviceEnums.getEnumForDropdown('remark', true);

			this.filterDropdowns = {
				Remark,
				RemarkOwner,
			};
		},

		getConditionTagText(condition) {
			return this.conditionDict[condition].Text.substring(0, 14);
		},

		getEditLinksListItems(auAllowed) {
			return editLinksListItems.filter((item) => {
				return typeof item.forAu === 'undefined' || item.forAu == auAllowed;
			});
		},

		async getEmailSubjects() {
			const emailIds = '7,50,51,59,60,63,30,29,28,1622';
			return servicePortalEmail.getEmailSubjectsByIds(emailIds).then((resp) => resp.data);
		},
	},
};
</script>

<style lang="scss">
.ObjectTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row {
		> th:nth-child(8) {
			max-width: 100px;
		}
		.VueTables__Created-filter-wrapper {
			.form-control.reportrange-text {
				display: flex;
				overflow-x: hidden;
				> span {
					overflow-x: hidden;
				}
			}
		}
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 120px;
		width: 120px;
	}
	.VueTables__filters-row > th:nth-child(3) {
		min-width: 50px;
		width: 50px;
	}
	.VueTables__filters-row > th:nth-child(4) {
		min-width: 50px;
		width: 50px;
	}
}

.object-preview-popover.b-popover {
	width: 1050px;
	max-width: 1050px;
	height: 350px;

	.popover-body {
		padding: 2px;
	}
}

.object-id {
	cursor: pointer;
}

.object-preview {
	width: 1044px;
	height: 344px;
	padding: 10px;
	overflow-y: auto;
}
</style>
