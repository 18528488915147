export const tableColumns = [
	{
		model: 'Remark',
		i18n: 'remark2317',
		sortable: true,
	},
	{
		model: 'UserId',
		i18n: 'pid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'Id',
		i18n: 'oid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'Name',
		i18n: 'object',
		sortable: true,
		filter: 'text',
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
	{
		colType: 'boolean',
		model: 'AccommodationUnitsAllowed',
		i18n: 'uj',
		sortable: true,
	},
	{
		model: 'RecommendId',
		i18n: 'recommendationf2483',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'Created',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'ChangedByOwner',
		i18n: 'changedbylandlo',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'ChangedByAdmin',
		i18n: 'changedbyadmin',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'ValidTo',
		i18n: 'validto',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'VerifiedDate',
		i18n: 'completed3436',
		hidden: true,
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'VerifiedByUser',
		i18n: 'whocompletedit',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'IsVerified',
		i18n: 'completed',
		sortable: true,
		filter: 'dropdown',
	},
];
