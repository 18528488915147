var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "ObjectTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          customFilterDropdowns: _vm.filterDropdowns,
          customHeadingTranslations: { RemarkOwner: _vm.$t("colleague") },
          headerTitle: "objects2378",
          tooltipMsg: "manualforobject",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "Id", ascending: 0 },
          modelId: "ObjectId",
          addNewRecordMsg: "",
        },
        on: {
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "filter__Remark",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.Remark },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.Remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "Remark", $$v)
                          },
                          expression: "filterModel.Remark",
                        },
                      }),
                      _c("label", [_vm._v("  ")]),
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.RemarkOwner },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.RemarkOwner,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "RemarkOwner", $$v)
                          },
                          expression: "filterModel.RemarkOwner",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "Remark",
              fn: function (list) {
                return [
                  _c("remark-indicate", {
                    attrs: {
                      entityId: list.row.Id,
                      entityTypeId: _vm.remarkEntityType,
                      refreshMethod: _vm.tableGetData,
                      defaultRemark: list.row.Remark,
                      administratorList: _vm.administratorList,
                    },
                  }),
                ]
              },
            },
            {
              key: "UserId",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/contact/" + list.row.ContactId,
                        target: "_blank",
                      },
                    },
                    [_vm._v("P" + _vm._s(list.row.UserId))]
                  ),
                ]
              },
            },
            {
              key: "Id",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "object-id",
                      attrs: { id: "obj-" + list.row.Id },
                    },
                    [_vm._v("O" + _vm._s(list.row.Id))]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "obj-" + list.row.Id,
                        placement: "auto",
                        triggers: "hover",
                        delay: "200",
                        "custom-class": "object-preview-popover",
                      },
                      on: {
                        show: function ($event) {
                          _vm.itemIdToPreview = list.row.Id
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.itemIdToPreview == list.row.Id
                            ? _c("object-preview", {
                                staticClass: "object-preview",
                                attrs: {
                                  preview: list.row.Preview,
                                  objectId: list.row.Id,
                                  userId: list.row.UserId,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "Name",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          list.row.UrlDomain +
                          "/" +
                          list.row.UrlDetail +
                          "?tkn=" +
                          _vm.user.AutologCode,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.Name))]
                  ),
                ]
              },
            },
            {
              key: "RecommendId",
              fn: function (list) {
                return [
                  list.row.IsRecommendAllowed
                    ? _c("recommend-cell", {
                        attrs: {
                          contactId: list.row.ContactId,
                          recommendId: list.row.RecommendId,
                          recommendValue: list.row.RecommendValue,
                          recommendIdTexts: _vm.recommendIdTexts,
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "Created",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("dateTime")(list.row.Created)))]
              },
            },
            {
              key: "ChangedByOwner",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm._f("dateTime")(list.row.ChangedByOwner))),
                ]
              },
            },
            {
              key: "ChangedByAdmin",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm._f("dateTime")(list.row.ChangedByAdmin))),
                ]
              },
            },
            {
              key: "VerifiedDate",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm._f("dateTime")(list.row.VerifiedDate))),
                ]
              },
            },
            {
              key: "ValidTo",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("date")(list.row.ValidTo)))]
              },
            },
            {
              key: "IsVerified",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row" },
                    [
                      _c("boolean-display", {
                        attrs: { boolValue: list.row.IsVerified },
                      }),
                      list.row.Condition == _vm.objectCondition.verified
                        ? _c("boolean-display", {
                            staticClass: "ml-2",
                            attrs: {
                              boolValue:
                                list.row.Condition ==
                                _vm.objectCondition.verified,
                              badgeTitle: _vm.getConditionTagText(1),
                              title: _vm.conditionDict[1].Text,
                            },
                          })
                        : _vm._e(),
                      list.row.Condition == _vm.objectCondition.notVerified
                        ? _c("boolean-display", {
                            staticClass: "ml-2",
                            attrs: {
                              boolValue:
                                list.row.Condition ==
                                _vm.objectCondition.notVerified,
                              badgeTitle: _vm.getConditionTagText(2),
                              title: _vm.conditionDict[2].Text,
                            },
                          })
                        : _vm._e(),
                      list.row.Condition == _vm.objectCondition.objectState
                        ? _c("boolean-display", {
                            staticClass: "ml-2",
                            attrs: {
                              boolValue:
                                list.row.Condition ==
                                _vm.objectCondition.objectState,
                              badgeTitle: _vm.getConditionTagText(3),
                              title: _vm.conditionDict[3].Text,
                            },
                          })
                        : _vm._e(),
                      list.row.InternalNote
                        ? _c("note-display", {
                            staticClass: "ml-2",
                            attrs: { value: list.row.InternalNote },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "Edit",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-end",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                id: "dropdown-header",
                                text: _vm.$t("edit2391"),
                                variant: "primary",
                              },
                            },
                            _vm._l(
                              _vm.getEditLinksListItems(
                                list.row.AccommodationUnitsAllowed
                              ),
                              function (link) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: link.id,
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        list.row.UrlDomain +
                                        "/" +
                                        link.pageName +
                                        list.row.Id +
                                        "?tkn=" +
                                        _vm.user.AutologCode,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(link.title))),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("email-send-dropdown", {
                        attrs: {
                          buttonText: _vm.$t("sendanoffer"),
                          pesLogTypeId: _vm.pesLogTypeId,
                          offersList: _vm.emailsList,
                          entityId: +list.row.Id,
                          withTable: false,
                        },
                      }),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              list.row.UrlDomain +
                              "/userManagement/myobjects/" +
                              list.row.Id +
                              "?tkn=" +
                              list.row.UserAutologCode,
                            target: "_blank",
                          },
                        },
                        [
                          _c("megau-button", {
                            attrs: {
                              classprop: "btn btn-warning ml-1",
                              icon: "key",
                              tooltip: _vm.$t("logintothelandl"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-success mr-1 ml-1",
                          icon: "cogs",
                          tooltip: _vm.$t("settings"),
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.redirectToDetail(list.row.Id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          1274624951
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }