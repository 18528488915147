var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "preview-basic" }, [
        _c("table", [
          _c("tr", [
            _c("td", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("object2623"))),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.preview.OName))]),
            _c("td", [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.preview.OEmail) + "\n\t\t\t\t\t"
              ),
              _vm.preview.OWeb
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.getWebAddressesHtml(_vm.preview.OWeb)
                      ),
                    },
                  })
                : _vm._e(),
            ]),
            _c("td", {
              domProps: {
                innerHTML: _vm._s(_vm.getPhonesHtml(_vm.preview.OPhones)),
              },
            }),
          ]),
          _c("tr", [
            _c("td", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("kontact"))),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.preview.PName))]),
            _c("td", [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.preview.PEmail) + "\n\t\t\t\t\t"
              ),
              _vm.preview.PWeb
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.getWebAddressesHtml(_vm.preview.PWeb)
                      ),
                    },
                  })
                : _vm._e(),
            ]),
            _c("td", {
              domProps: {
                innerHTML: _vm._s(_vm.getPhonesHtml(_vm.preview.PPhones)),
              },
            }),
          ]),
          _c("tr", [
            _c("td", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("billingaddress2625"))),
            ]),
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v(_vm._s(_vm.getInvoiceAddress())),
            ]),
          ]),
        ]),
      ]),
      _c("products-table", {
        attrs: {
          objectId: _vm.objectId,
          userId: _vm.userId,
          editable: false,
          markCurrentPackage: true,
          showTitle: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }